<template>
  <v-container v-if="!loadView" fluid class="p-0">
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row>
          <v-col cols="1" md="1" class="px-0 pl-3 pt-3 pb-0">
            <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
          </v-col>
          <v-col cols="11" md="8" class="pb-0 pl-0 pt-0 px-0" tabindex="-1">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-card class="p-2 pl-0">
          <v-card-text class="pt-0 pb-3">
            <v-row class="py-0">
              <v-col cols="6" class="pb-2 pt-0 pl-4" tabindex="0">
                <span v-if="esAnulacion" class="error--text"
                  >El comprobante es de anulación, no se puede editar los
                  valores.</span
                >
              </v-col>
              <v-col cols="6" class="p-0" align="end">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text to-right"
                      @click="showHeader = !showHeader"
                    >
                      {{ !showHeader ? chevronDownIcon : chevronUpIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showHeader ? "Mostrar cabecera" : "Ocultar cabecera"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-if="showHeader"
                v-model="isFormValid"
                ref="formEditPVIV"
                form="formEditPVIV"
                id="formEditPVIV"
                @submit.prevent="saveEditPVIV()"
              >
                <!-- Nombre -->
                <v-row class="pb-6">
                  <v-col cols="3" class="py-0 pr-2">
                    <v-autocomplete
                      v-model="empresaSelected"
                      :items="empresas"
                      item-text="value"
                      item-value="id"
                      return-object
                      label="Empresas"
                      outlined
                      hide-details="auto"
                      dense
                      :disabled="
                        (tipoComprobanteSelected &&
                          tCompEsPagoRetencion &&
                          selectRetenciones &&
                          selectRetenciones.length > 0) ||
                        !canEdit ||
                        ($route.params.mprovId !== undefined &&
                          $route.params.mprovId !== null) ||
                        this.valoresItems.length > 0
                      "
                      :filled="
                        (tipoComprobanteSelected &&
                          tCompEsPagoRetencion &&
                          selectRetenciones &&
                          selectRetenciones.length > 0) ||
                        !canEdit ||
                        ($route.params.mprovId !== undefined &&
                          $route.params.mprovId !== null) ||
                        this.valoresItems.length > 0
                      "
                      :rules="rules.required"
                      id="empresaPVIV"
                      @change="setImputacionContableEmpresa()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-0 pl-0 pr-2">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tipoComprobantesItems"
                      item-text="tCompNombre"
                      item-value="tCompId"
                      return-object
                      label="Tipo de comprobante"
                      outlined
                      hide-details="auto"
                      dense
                      :disabled="
                        (tCompEsPagoRetencion &&
                          selectRetenciones &&
                          selectRetenciones.length > 0) ||
                        !canEdit ||
                        ($route.params.mprovId !== undefined &&
                          $route.params.mprovId !== null) ||
                        this.valoresItems.length > 0
                      "
                      :filled="
                        (tCompEsPagoRetencion &&
                          selectRetenciones &&
                          selectRetenciones.length > 0) ||
                        !canEdit ||
                        ($route.params.mprovId !== undefined &&
                          $route.params.mprovId !== null) ||
                        this.valoresItems.length > 0
                      "
                      :rules="rules.required"
                      id="tipoCompPVIV"
                      @input="setDatosTipoComprobante()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="pr-0 pl-0 py-0">
                    <v-menu
                      ref="fecha"
                      v-model="menuFecha"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaSelected"
                          label="Fecha"
                          hint="Formato DD/MM/AAAA"
                          @blur="fecha = helpers.parseDateToIso(fechaSelected)"
                          outlined
                          :disabled="(!canEdit && !enableSaveBtn)"
                          :filled="(!canEdit && !enableSaveBtn)"
                          dense
                          v-mask="'##/##/####'"
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                          id="fechaPVIV"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(
                                helpers.parseDateToIso(fechaContableSelected)
                              ) >=
                                new Date(
                                  helpers.parseDateToIso(fechaSelected)
                                ) || 'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fecha"
                        no-title
                        @change="fechaSelected = helpers.formatDate(fecha)"
                        @input="menuFecha = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" class="pr-0 pl-2 py-0">
                    <v-menu
                      ref="fechaContable"
                      v-model="menuFechaContable"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaContableSelected"
                          label="Fecha contable"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaContable = helpers.parseDateToIso(
                              fechaContableSelected
                            )
                          "
                          outlined
                          hide-details="auto"
                          :disabled="(!canEdit && !enableSaveBtn)"
                          :filled="(!canEdit && !enableSaveBtn)"
                          dense
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          id="fechaContablePVIV"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(
                                helpers.parseDateToIso(fechaContableSelected)
                              ) >=
                                new Date(
                                  helpers.parseDateToIso(fechaSelected)
                                ) || 'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaContable"
                        no-title
                        @change="
                          fechaContableSelected =
                            helpers.formatDate(fechaContable)
                        "
                        @input="menuFechaContable = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="mt-n4 pl-3">
                  <!-- Letra y numero de comprobante -->
                  <v-col cols="12" md="1" class="pl-1 py-0 pr-1">
                    <v-text-field
                      type="text"
                      class="center-input"
                      v-model="letraComprobante"
                      outlined
                      dense
                      persistent-hint
                      v-mask="'A'"
                      label="Letra"
                      id="letraPVIV"
                      hide-details="auto"
                      :rules="rules.required"
                      :filled="tieneNumeradorAutomatico || esAnulacion"
                      :disabled="tieneNumeradorAutomatico || esAnulacion"
                    ></v-text-field>
                  </v-col>
                  <v-col class="p-0" cols="12" md="1">
                    <v-text-field
                      type="text"
                      v-model="sucursalComprobante"
                      outlined
                      dense
                      class="center-input"
                      v-mask="'#####'"
                      persistent-hint
                      label="Sucursal"
                      id="sucursalPVIV"
                      hide-details="auto"
                      :rules="rules.required"
                      :filled="
                        tieneNumeradorAutomatico || !canEdit || esAnulacion
                      "
                      :disabled="
                        tieneNumeradorAutomatico || !canEdit || esAnulacion
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col class="p-0 pl-1 pr-2" cols="12" md="2">
                    <v-text-field
                      type="text"
                      v-model="nroComprobante"
                      outlined
                      dense
                      v-mask="'########'"
                      persistent-hint
                      label="Número"
                      hide-details="auto"
                      id="numeroPVIV"
                      :rules="rules.required"
                      :filled="
                        tieneNumeradorAutomatico || !canEdit || esAnulacion
                      "
                      :disabled="
                        tieneNumeradorAutomatico || !canEdit || esAnulacion
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="parametroGeneralMultimoneda"
                    cols="12"
                    md="2"
                    class="pt-0 pr-0 pl-0"
                  >
                    <v-autocomplete
                      v-model="monedaSelected"
                      :items="monedas"
                      @change="
                        monedaSelected ? getCotizacion(monedaSelected.id) : null
                      "
                      return-object
                      item-value="id"
                      item-text="value"
                      outlined
                      :rules="rules.required"
                      clearable
                      dense
                      :disabled="
                        (tCompEsPagoRetencion &&
                          selectRetenciones &&
                          selectRetenciones.length > 0) ||
                        valoresItems.length > 0 ||
                        esAnulacion ||
                        (this.$route.params.mprovId !== undefined &&
                          this.$route.params.mprovId !== null)
                      "
                      :filled="
                        (tCompEsPagoRetencion &&
                          selectRetenciones &&
                          selectRetenciones.length > 0) ||
                        valoresItems.length > 0 ||
                        esAnulacion ||
                        (this.$route.params.mprovId !== undefined &&
                          this.$route.params.mprovId !== null)
                      "
                      label="Moneda"
                      id="monedaPVIV"
                      hide-details="auto"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="monedaDistintaDefecto && parametroGeneralMultimoneda"
                    cols="12"
                    md="2"
                    class="pt-0 pl-2 pr-2 pb-0"
                  >
                    <currency-input
                      :loading="cotizacionLoading"
                      dense
                      v-model="cotizacion"
                      outlined
                      :filled="
                        (tipoComprobanteSelected &&
                          tCompEsPagoRetencion &&
                          selectRetenciones.length > 0) ||
                        valoresItems.length > 0 ||
                        !canEdit
                      "
                      :disabled="
                        (tipoComprobanteSelected &&
                          tCompEsPagoRetencion &&
                          selectRetenciones.length > 0) ||
                        valoresItems.length > 0 ||
                        !canEdit
                      "
                      :rules="
                        monedaDistintaDefecto && parametroGeneralMultimoneda
                          ? rules.required.concat(
                              rules.decimalGreaterThanZero(cotizacion)
                            )
                          : []
                      "
                      :options="currencyOptions"
                      hide-details="auto"
                      label="Cotización"
                      id="cotizacionPVIV"
                    ></currency-input>
                  </v-col>
                  <v-col
                    v-if="monedaDistintaDefecto && parametroGeneralMultimoneda"
                    cols="12"
                    md="2"
                    class="py-0 pl-0 pr-0"
                  >
                    <currency-input
                      type="number"
                      class="center-input"
                      v-model="importeMonedaExtranjera"
                      outlined
                      :disabled="
                        tCompEsPagoRetencion || !canEdit || esAnulacion
                      "
                      :filled="tCompEsPagoRetencion || !canEdit || esAnulacion"
                      :label="`Importe (${monedaExtranjeraNom})`"
                      dense
                      @blur="calcularTotalEnPeso()"
                      :rules="[]"
                      :options="currencyOptions"
                      id="importeMonedaExtranjeraPVIV"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col cols="12" md="2" class="py-0 pl-2 pr-0">
                    <currency-input
                      type="number"
                      class="center-input"
                      v-model="importe"
                      outlined
                      :disabled="
                        tCompEsPagoRetencion ||
                        !canEdit ||
                        monedaDistintaDefecto ||
                        esAnulacion
                      "
                      :filled="
                        tCompEsPagoRetencion ||
                        !canEdit ||
                        monedaDistintaDefecto ||
                        esAnulacion
                      "
                      :label="`Importe (${monedaPorDefectoNom})`"
                      dense
                      id="importeMonedaDefectoPVIV"
                      @blur="calcularTotalEnDolar()"
                      :options="currencyOptions"
                      :rules="[]"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                </v-row>
                <v-row
                  :class="
                    tipoComprobanteSelected && tCompEsPagoRetencion
                      ? 'mb-n4'
                      : 'mb-n9'
                  "
                >
                  <v-col
                    cols="12"
                    md="12"
                    :class="
                      parametroGeneralMultimoneda
                        ? 'py-0 pl-4 pr-0 mt-n1'
                        : 'py-0 pl-4 pr-0 mt-2'
                    "
                  >
                    <v-text-field
                      type="text"
                      class="center-input"
                      v-model="concepto"
                      outlined
                      label="Concepto"
                      id="conceptoPVIV"
                      dense
                      persistent-hint
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="tipoComprobanteSelected && tCompEsPagoRetencion">
                  <v-col cols="12" class="pr-0 pt-0 pl-4">
                    <v-data-table
                      :headers="filteredHeadersRet"
                      :items="selectRetenciones"
                      class="elevation-2"
                      item-key="mpValId"
                      dense
                      :hide-default-footer="
                        selectRetenciones && selectRetenciones.length < 10
                          ? true
                          : false
                      "
                    >
                      <template v-slot:top>
                        <v-row>
                          <v-col
                            cols="12"
                            class="pt-1 pr-6 text-right"
                            align="end"
                          >
                            <v-btn
                              small
                              :disabled="
                                !canEdit ||
                                !empresaSelected ||
                                (monedaDistintaDefecto && !cotizacion)
                              "
                              ref="selectRetenciones"
                              @click="toggleModalReteneciones"
                              color="primary"
                            >
                              Seleccionar retenciones
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`item.imp`]="{ item }">
                        <span>{{
                          helpers.floatToMoneyString(item.imp, "", true)
                        }}</span>
                      </template>
                      <template v-slot:[`item.importeComp`]="{ item }">
                        <span>{{
                          helpers.floatToMoneyString(item.importeComp, "", true)
                        }}</span>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                          size="20"
                          :disabled="!canEdit"
                          @click="
                            () => {
                              selectRetenciones = selectRetenciones.filter(
                                (x) => x !== item
                              );
                              recalcularImportes();
                            }
                          "
                        >
                          {{ deleteIcon }}
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-row>
            <v-col
              col="8"
              md="8"
              class="pt-0 pb-0"
              align="start"
              align-self="center"
            >
              <v-btn-toggle dense v-model="text" color="primary" group>
                <v-btn
                  value="VALORES"
                  id="btnValoresPVIV"
                  @click="switchTab(true)"
                >
                  Valores
                </v-btn>
                <v-btn
                  :disabled="
                    (tipoComprobanteSelected &&
                      !esAnulacion &&
                      (!tipoComprobanteSelected.llevaImputCble ||
                        !datosImputacionContable)) ||
                    !empresaSelected ||
                    !tipoComprobanteSelected
                  "
                  @click="switchTab(false)"
                  id="btnImputContablePVIV"
                >
                  Imputación contable
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="2" class="pt-2 pb-2 pr-6 text-right">
              <v-btn
                v-if="showValores"
                small
                :disabled="
                  !empresaSelected ||
                  !tipoComprobanteSelected ||
                  !canEdit ||
                  esAnulacion ||
                  (tipoComprobanteSelected &&
                    tipoComprobanteSelected.esIngresosVarios) ||
                  (tipoComprobanteSelected && !tipoComprobanteSelected.valores)
                "
                @click="
                  () => {
                    if (valoresItems.length > 0) {
                      idsValoresExcluir = valoresItems.map((val) => val.valId);
                    } else idsValoresExcluir = [];
                    toggleModalValoresCartera();
                  }
                "
                color="primary"
                id="btnValoresCarteraPVIV"
                >Valores en cartera</v-btn
              >
            </v-col>
            <v-col cols="2" class="pt-2 pb-2 pr-6 text-right" align="end">
              <v-btn
                v-if="showValores"
                small
                :disabled="
                  !empresaSelected ||
                  !tipoComprobanteSelected ||
                  !canEdit ||
                  esAnulacion ||
                  tipoComprobanteSelected == null ||
                  (tipoComprobanteSelected && !tipoComprobanteSelected.valores)
                "
                ref="btnToggleNuevoTipoValor"
                @click="toggleNuevoTipoValor"
                color="primary"
                id="btnNuevoValorPVIV"
              >
                Nuevo valor
              </v-btn>
            </v-col>
          </v-row>
          <section v-if="showValores" class="px-2">
            <v-data-table
              :headers="filteredHeadersValores"
              :items="valoresItems"
              class="elevation-1 mt-1"
              dense
              :hide-default-footer="valoresItems && valoresItems.length <= 10"
            >
              <template v-slot:[`item.imp`]="{ item }">
                <span>
                  {{ helpers.floatToMoneyString(item.imp, "", true) }}
                </span>
              </template>
              <template v-slot:[`item.importeComp`]="{ item }">
                <span>{{
                  helpers.floatToMoneyString(item.importeComp, "", true)
                }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  size="20"
                  :disabled="!canEdit || esAnulacion"
                  @click="
                    () => {
                      valoresItems = valoresItems.filter((x) => x !== item);
                      recalcularValores();
                    }
                  "
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
            </v-data-table>
          </section>
          <section v-else class="px-1 pb-1">
            <v-card class="pl-4 pr-4" outlined>
              <v-row>
                <!-- IMPUTACION CONTABLE -->
                <v-col cols="12" md="6" class="p-0">
                  <v-data-table
                    :headers="imputacionContableHeaders"
                    :items="imputacionContableItems"
                    fixed-header
                    class="elevation-1 pb-1"
                    hide-default-footer
                    dense
                    item-key="id"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="12" md="6" class="pb-0 pt-2">
                          <span
                            class="primary--text py-0 pl-2 font-weight-medium"
                            >Imputación contable</span
                          >
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pb-0 pt-2 pr-5 text-right"
                        >
                          <v-btn
                            small
                            @click="toggleNuevoImputacionContable"
                            class="py-0"
                            :disabled="!empresaSelected || !canEdit"
                            color="primary"
                            id="btnNuevoCentroCostosPVIV"
                          >
                            {{
                              (imputacionContableItems &&
                                imputacionContableItems.length > 0) ||
                              centroCostosItems.length > 0
                                ? "Modificar"
                                : "Nuevo"
                            }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                      <span>{{
                        helpers.floatToMoneyString(item.importe)
                      }}</span>
                    </template>
                    <template v-slot:footer>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-1 pb-2 pl-0 pr-6 text-right"
                        >
                          <span class="font-weight-medium text-right">
                            Total imputación contable:
                            {{
                              helpers.floatToMoneyString(
                                totalImputacionContable,
                                ""
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
                <!-- CENTRO DE COSTOS -->
                <v-col cols="6" class="p-0">
                  <v-data-table
                    :headers="centroCostosHeaders"
                    :items="centroCostosItems"
                    height="100%"
                    fixed-header
                    class="elevation-1 pb-1 pt-1"
                    hide-default-footer
                    dense
                    item-key="id"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="12" md="6" class="pt-2 pb-0">
                          <span
                            class="primary--text font-weight-medium py-0 pl-2"
                            >Centros de costos</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                      <span>{{
                        helpers.floatToMoneyString(item.importe)
                      }}</span>
                    </template>
                    <template v-slot:footer>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-1 pb-2 pl-0 pr-6 text-right"
                        >
                          <span class="font-weight-medium text-right">
                            Total centros de costos:
                            {{
                              helpers.floatToMoneyString(
                                totalCentrosDeCostos,
                                ""
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </section>
          <v-row v-if="showValores" class="pt-1 pb-1">
            <v-col cols="6"></v-col>
            <v-col cols="3" align="end" class="text-right pr-0">
              <currency-input
                v-if="monedaDistintaDefecto && parametroGeneralMultimoneda"
                :label="`Total (${monedaExtranjeraNom})`"
                v-model="totalValoresMonedaExtranjera"
                readonly
                filled
                disabled
                dense
                outlined
                id="totalMonedaExtranjeraPVIV"
                :rules="[]"
                :options="currencyOptions"
                :hideDetails="'true'"
              ></currency-input>
            </v-col>
            <v-col cols=" 3" align="end" class="text-right pr-6">
              <currency-input
                :label="`Total (${monedaPorDefectoNom})`"
                v-model="totalValores"
                readonly
                disabled
                filled
                dense
                outlined
                id="totalMonedaDefectoPVIV"
                :rules="[]"
                :options="currencyOptions"
                :hideDetails="'true'"
              ></currency-input>
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col cols="12" align="end" class="text-right">
            <v-btn
              type="submit"
              :loading="loadingBtnSave"
              :disabled="
                !isFormValid ||
                (!canEdit && !enableSaveBtn) ||
                valoresItems.length == 0 ||
                (!tieneNumeradorAutomatico &&
                  this.$route.params.mprovId == undefined)
              "
              form="formEditPVIV"
              color="primary"
              id="btnGuardarPVIV"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog
          v-if="openModalSelectRetenciones"
          v-model="openModalSelectRetenciones"
          max-width="75%"
          @keydown.esc="toggleModalReteneciones"
          persistent
        >
          <v-card>
            <v-container class="p-2 pt-0">
              <v-card-text class="pt-0 pb-8">
                <v-row>
                  <v-col cols="6">
                    <v-card-title class="pl-0 pb-0 pt-0">
                      <span class="primary--text">{{
                        "Seleccionar retenciones"
                      }}</span>
                    </v-card-title>
                  </v-col>
                  <v-col cols="6" align="end">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="to-right"
                          icon
                          @click="toggleModalReteneciones"
                        >
                          <v-icon
                            id="btnCerrarSeleccionarRetencionesPVIV"
                            v-bind="attrs"
                            v-on="on"
                            >{{ closeIcon }}</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Cerrar</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-card class="elevation-2 mb-6 mt-n2" outlined>
                  <v-row>
                    <v-col
                      cols="10"
                      class="py-0"
                      align="left"
                      @click="showFilters = true"
                    >
                      <FiltersSelected
                        :filters="filtersApplied"
                        v-if="!showFilters"
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      align="end"
                      align-self="end"
                      class="pt-0 pr-8"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="primary--text"
                            id="btnMostrarFiltrosPVIV"
                            @click.stop="showFilters = !showFilters"
                          >
                            {{ closeFiltersIcon }}
                          </v-icon>
                        </template>
                        <span>{{
                          !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                        }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <v-form
                      v-show="showFilters"
                      v-model="isFormFiltersValid"
                      ref="filters-form"
                      id="filters-form"
                      @submit.prevent="getRetencionesByFilters()"
                    >
                      <v-row class="mb-n6">
                        <!-- tipo de fecha -->
                        <v-col cols="2" class="p-0 pl-6 pb-1">
                          <v-autocomplete
                            v-model="tipoFechaSelected"
                            :items="itemsTiposFecha"
                            return-object
                            label="Tipo de fecha"
                            item-text="value"
                            item-value="id"
                            id="tipoFechaSeleccionarRetencionesPVIV"
                            outlined
                            dense
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- fecha desde -->
                        <v-col cols="12" sm="2" md="2" class="p-0 pl-1 pb-1">
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            hide-details="auto"
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fechaDesdeSelected"
                                label="Fecha desde"
                                :append-icon="calendarIcon"
                                v-bind="attrs"
                                outlined
                                dense
                                id="fechaDesdeSeleccionarRetencionesPVIV"
                                hint="Formato DD/MM/AAAA"
                                v-mask="'##/##/####'"
                                onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                                @blur="
                                  fechaDesde =
                                    helpers.parseDateToIso(fechaDesdeSelected)
                                "
                                v-on="on"
                                :rules="
                                  rules.required.concat(
                                    rules.validDate,
                                    new Date(
                                      helpers.parseDateToIso(fechaDesdeSelected)
                                    ) <=
                                      new Date(
                                        helpers.parseDateToIso(
                                          fechaHastaSelected
                                        )
                                      ) || 'Formato incorrecto'
                                  )
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fechaDesde"
                              no-title
                              @change="
                                fechaDesdeSelected =
                                  helpers.formatDate(fechaDesde)
                              "
                              @input="menu1 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <!-- fecha hasta -->
                        <v-col cols="12" sm="2" md="2" class="pt-0 pl-1 pr-1">
                          <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            hide-details="auto"
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fechaHastaSelected"
                                label="Fecha hasta"
                                :append-icon="calendarIcon"
                                v-bind="attrs"
                                outlined
                                hide-details="auto"
                                dense
                                id="fechaHastaSeleccionarRetencionesPVIV"
                                hint="Formato DD/MM/AAAA"
                                v-mask="'##/##/####'"
                                onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                                @blur="
                                  fechaHasta =
                                    helpers.parseDateToIso(fechaHastaSelected)
                                "
                                v-on="on"
                                :rules="
                                  rules.required.concat(
                                    rules.validDate,
                                    new Date(
                                      helpers.parseDateToIso(fechaDesdeSelected)
                                    ) <=
                                      new Date(
                                        helpers.parseDateToIso(
                                          fechaHastaSelected
                                        )
                                      ) || 'Formato incorrecto'
                                  )
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fechaHasta"
                              no-title
                              @change="
                                fechaHastaSelected =
                                  helpers.formatDate(fechaHasta)
                              "
                              @input="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <!-- Empresa -->
                        <v-col cols="12" sm="3" md="3" class="py-0 p-0 pl-n3">
                          <v-autocomplete
                            disabled
                            filled
                            v-model="empresaSelected"
                            :items="empresas"
                            item-text="value"
                            item-value="id"
                            return-object
                            id="empresasSeleccionarRetencionesPVIV"
                            label="Empresas"
                            outlined
                            hide-details="auto"
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <!-- Tipo retención -->
                        <v-col cols="12" sm="3" md="3" class="py-0 pl-1 pr-8">
                          <v-autocomplete
                            v-model="tipoRetencionSelected"
                            :items="tipoRetencionesItems"
                            item-text="value"
                            item-value="id"
                            :rules="rules.required"
                            return-object
                            outlined
                            id="tipoRetencionSeleccionarRetencionesPVIV"
                            dense
                            clearable
                            label="Tipo retención"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n6 mb-2">
                        <v-col
                          cols="12"
                          align="end"
                          class="text-right to-right pr-8 pt-3 pb-0"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                size="28"
                                id="limpiarFiltrosSeleccionarRetencionesPVIV"
                                @click="resetForm"
                              >
                                {{ clearFiltersIcon }}
                              </v-icon>
                            </template>
                            <span>Limpiar filtros</span>
                          </v-tooltip>
                          <v-btn
                            color="primary"
                            elevation="2"
                            small
                            :disabled="!isFormFiltersValid"
                            type="submit"
                            form="filters-form"
                            id="btnAplicarSeleccionarRetencionesPVIV"
                          >
                            Aplicar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expand-transition>
                </v-card>
                <v-data-table
                  :headers="filteredHeadersRet"
                  :items="itemsRetenciones"
                  height="100%"
                  class="elevation-4"
                  v-model="selectRetenciones"
                  @toggle-select-all="selectAllToggle"
                  :loading="isLoadingTable"
                  show-select
                  item-key="mpValId"
                  @click:row="selectWhenClickRow($event)"
                >
                  <template v-slot:[`item.imp`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.imp, "", true)
                    }}</span>
                  </template>
                  <template v-slot:[`item.importeComp`]="{ item }">
                    <span>{{
                      helpers.floatToMoneyString(item.importeComp, "", true)
                    }}</span>
                  </template>
                  <template
                    v-slot:[`item.data-table-select`]="{ isSelected, select }"
                  >
                    <v-simple-checkbox
                      :value="isSelected"
                      color="primary"
                      @input="select($event)"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </template>
                </v-data-table>
                <v-col cols="12" class="pr-0">
                  <v-btn
                    @click="toggleModalReteneciones"
                    class="to-right"
                    :disabled="
                      selectRetenciones && selectRetenciones.length == 0
                    "
                    color="primary"
                    id="btnAgregarSeleccionarRetencionesPVIV"
                    >Agregar seleccionados</v-btn
                  >
                </v-col>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- MODAL NUEVO TIPO DE VALOR -->
        <v-dialog
          v-if="openModalNuevoValor"
          v-model="openModalNuevoValor"
          max-width="60%"
          @keydown.esc="toggleNuevoTipoValor"
          persistent
        >
          <NuevoTipoValor
            @toggleNuevoTipoValor="toggleNuevoTipoValor"
            @addTipoDeValor="addTipoDeValor"
            :empresaSelected="empresaSelected"
            :movProvId="0"
            :retencionesItems="valoresItems"
            :tipoValor="''"
            :fechaSelected="fechaSelected"
            :cotizacion="
              monedaDistintaDefecto ? parseFloat(cotizacion) : parseFloat(1)
            "
            :monedaOPSelected="monedaSelected"
            :importeSugerido="importeSugerido"
            :esPVIV="true"
            :agregarCartera="
              !monedaDistintaDefecto &&
              tipoComprobanteSelected &&
              tipoComprobanteSelected.esIngresosVarios
            "
          ></NuevoTipoValor>
        </v-dialog>
        <!-- MODAL BUSCAR VALORES CARTERA -->
        <v-dialog
          v-model="buscarValoresCarteraModal"
          v-if="buscarValoresCarteraModal"
          @keydown.esc="toggleModalValoresCartera"
          max-width="70%"
          persistent
        >
          <BuscarValoresEnCartera
            :empresaSelectedId="empresaSelected.id"
            :idsValoresExcluir="idsValoresExcluir"
            @toggleModalValoresCartera="toggleModalValoresCartera"
            @setValoresSelected="setValoresCartera"
          ></BuscarValoresEnCartera>
        </v-dialog>
        <!-- MODAL NUEVA IMPUTACION CONTABLE -->
        <v-dialog
          v-model="imputacionContableModal"
          v-if="imputacionContableModal"
          @keydown.esc="toggleNuevoImputacionContable"
          max-width="50rem"
          persistent
        >
          <ImputacionCbleCentroCostosCargaFactura
            :EntFacId="empresaSelected.id"
            :empresaIdConta="empresaIdConta"
            :datosImputacionContable="datosImputacionContable"
            :totalImputable="importe"
            :imputacionContableItemsProp="imputacionContableItemsProp"
            :centroCostosItemsProp="centroCostosItemsProp"
            @toggleNuevoImputacionContable="toggleNuevoImputacionContable"
            @reloadTables="reloadTablesImputacionContable"
          ></ImputacionCbleCentroCostosCargaFactura>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helpers from "@/utils/helpers.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import FiltersSelected from "@/components/shared/FiltersSelected";
import NuevoTipoValor from "@/components/modules/proveedores/ordenesPago/NuevoTipoValor.vue";
import BuscarValoresEnCartera from "@/components/modules/proveedores/ordenesPago/BuscarValoresEnCartera.vue";
import ImputacionCbleCentroCostosCargaFactura from "@/views/modules/proveedores/cuentaCorriente/ImputacionCbleCentroCostosCargaFactura.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "EditPVIV",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    BuscarValoresEnCartera,
    NuevoTipoValor,
    ImputacionCbleCentroCostosCargaFactura,
    CurrencyInput,
    GoBackBtn,
  },
  data: (vm) => ({
    enums: enums,
    rules: rules,
    helpers: helpers,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    deleteIcon: enums.icons.DELETE,
    closeIcon: enums.icons.CLOSE,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    volverIcon: enums.icons.BACK,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    showHeader: true,
    text: "VALORES",
    tipoComprobanteSelected: null,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999,
      },
      precision: 2,
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    },
    idsValoresExcluir: [],
    itemsRetenciones: [],
    tipoRetencionSelected: null,
    empresaIdConta: null,
    tipoRetencionesItems: [],
    tCompSigno: null,
    loadingBtnSave: false,
    headersRetenciones: [
      {
        text: "Fecha",
        sortable: false,
        align: "left",
        value: "fechaToShow",
      },
      {
        text: "Comprobante",
        sortable: false,
        align: "left",
        value: "comprobante",
      },
      {
        text: "Tipo retención",
        sortable: false,
        align: "left",
        value: "tipoRetencion",
      },
      {
        text: "Número retención",
        sortable: false,
        align: "right",
        value: "numero",
      },
      {
        text: "Importe",
        sortable: false,
        align: "right",
        value: "imp",
      },
      {
        text: "Importe",
        sortable: false,
        align: "right",
        value: "importeComp",
      },
      { text: "Eliminar", align: "end", value: "actions", sortable: false },
    ],
    selectRetenciones: [],
    empresaSelected: null,
    empresas: [],
    showExpand: false,
    showFilters: true,
    routeToGo: "ConsultaPVIV",
    isFormFiltersValid: false,
    headersValores: [
      { text: "Tipo valor", value: "tvNombre", sortable: false },
      { text: "Banco", sortable: false, value: "bancoNombre" },
      { text: "Cuenta", sortable: false, value: "bctaNombre" },
      { text: "Fecha", sortable: false, value: "fecha" },
      { text: "A orden de", sortable: false, value: "aOrdenDe" },
      { text: "Número", sortable: false, value: "numero" },
      { text: "Importe", align: "end", sortable: false, value: "imp" },
      {
        text: "Importe",
        align: "end",
        sortable: false,
        value: "importeComp",
      },
      { text: "Eliminar", align: "end", sortable: false, value: "actions" },
    ],
    valoresItems: [],
    //filtros
    tipoFechaSelected: { id: "F", value: "Fecha recepción" },
    itemsTiposFecha: [
      { id: "F", value: "Fecha recepción" },
      { id: "F", value: "Fecha de vencimiento" },
    ],
    fechaDesde: new Date(new Date().setMonth(new Date().getMonth() - 6))
      .toISOString()
      .substr(0, 10),
    fechaDesdeSelected: helpers.formatDate(
      new Date(new Date().setMonth(new Date().getMonth() - 6))
        .toISOString()
        .substr(0, 10)
    ),
    menu1: null,
    fechaHastaSelected: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaHasta: new Date().toISOString().substr(0, 10),
    menu2: null,
    filtersApplied: [],
    showValores: true,
    title: "Nuevo ingresos / egresos varios",
    isLoadingTable: false,
    openModalSelectRetenciones: false,
    showHelp: false,
    isFormValid: false,
    letraComprobante: "",
    sucursalComprobante: "",
    nroComprobante: "",
    importe: null,
    importeMonedaExtranjera: null,
    concepto: "",
    tipoComprobantesItems: [],
    fechaContable: new Date().toISOString().substr(0, 10),
    fecha: new Date().toISOString().substr(0, 10),
    menuFechaContable: null,
    menuFecha: null,
    imputacionContableModal: false,
    importeSugerido: 0,
    openModalNuevoValor: false,
    monedaSelected: null,
    monedas: [],
    monedaPorDefectoNom: "",
    totalCentrosDeCostos: 0,
    totalImputacionContable: 0,
    imputacionContableItems: [],
    centroCostosItems: [],
    imputacionContableItemsProp: [],
    centroCostosItemsProp: [],
    buscarValoresCarteraModal: false,
    totalValores: 0.0,
    totalValoresMonedaExtranjera: 0.0,
    // data table headers
    imputacionContableHeaders: [
      {
        text: "Cuenta",
        sortable: false,
        value: "cuenta",
        align: "start",
      },
      {
        text: "Descripción",
        sortable: false,
        value: "descripcion",
        align: "start",
      },
      {
        text: "Importe",
        sortable: false,
        value: "importe",
        align: "end",
      },
    ],
    centroCostosHeaders: [
      {
        text: "Nombre",
        sortable: false,
        value: "nombre",
        align: "start",
      },
      {
        text: "Importe",
        sortable: false,
        value: "importe",
        align: "end",
      },
    ],
    fechaContableSelected: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaSelected: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    parametroGeneralMultimoneda: false,
    datosImputacionContable: null,
    monedaPorDefecto: null,
    cotizacion: null,
    tieneNumeradorAutomatico: false,
    tCompEsPagoRetencion: false,
    cotizacionLoading: false,
    loadView: true,
    optionCode: enums.webSiteOptions.EDITAR_PV_IV,
    canEdit: true,
    enableSaveBtn: true,
    esAnulacion: false,
  }),
  computed: {
    monedaDistintaDefecto() {
      if (this.monedaSelected)
        return this.monedaSelected.id !== this.monedaPorDefecto.id;
    },
    monedaExtranjeraNom() {
      if (this.monedaSelected && this.parametroGeneralMultimoneda) {
        let result = this.monedaDistintaDefecto
          ? `${this.monedaSelected.value}`
          : "";

        return result;
      }
    },
    filteredHeadersValores() {
      return this.headersValores.filter((header) => {
        //usamos importeComp solo cuando la moneda del proveedor es distinta de la porDefecto.
        if (this.monedaDistintaDefecto && header.value == "importeComp")
          header.text = `Importe ${this.monedaExtranjeraNom?.toLowerCase()}`;

        if (header.value == "imp")
          header.text = this.monedaDistintaDefecto
            ? `Importe ${this.monedaPorDefectoNom?.toLowerCase()}`
            : "Importe";

        if (!this.monedaDistintaDefecto && header.value === "importeComp")
          return false;

        return true;
      });
    },
    filteredHeadersRet() {
      return this.headersRetenciones.filter((header) => {
        if (header.value == "importeComp" && this.openModalSelectRetenciones)
          return false;
        if (
          this.monedaDistintaDefecto &&
          header.value == "importeComp" &&
          !this.openModalSelectRetenciones
        )
          header.text = `Importe ${this.monedaExtranjeraNom?.toLowerCase()}`;

        if (header.value == "imp")
          header.text =
            this.monedaDistintaDefecto && !this.openModalSelectRetenciones
              ? `Importe ${this.monedaPorDefectoNom?.toLowerCase()}`
              : "Importe";

        if (!this.monedaDistintaDefecto && header.value === "importeComp")
          return false;
        if (header.text == "Eliminar" && this.openModalSelectRetenciones)
          return false;
        return true;
      });
    },
  },
  watch: {
    loadView(val) {
      if (!val) {
        this.$store.dispatch("user/changeAppLoading", {
          status: false,
          text: "",
          opaque: false,
        });
      }
    },
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: true,
      text: "",
      opaque: true,
    });
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como página visitada si ingreso por url
      this.optionCode
    );
    await this.setCombos();
    if (this.$route.params.esAnulacion) this.esAnulacion = true;
    else this.esAnulacion = false;
    if (this.$route.params.mprovId) {
      await this.setDatosEditPVIV();
      await this.validateEditPVIV();
    }
  },
  methods: {
    ...mapActions({
      getTipoCompPvIvAbm: "fondos/getTipoCompPvIvAbm",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getRetencionesAPagar: "fondos/getRetencionesAPagar",
      GetTiposValoresRetenciones: "fondos/GetTiposValoresRetenciones",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getParametrosGeneralesProveedor:
        "proveedores/getParametrosGeneralesProveedorCargaFactura",
      getCotizacionMoneda: "AdministracionSistema/getCotizacionMoneda",
      getImputContablesByEmpresaxFechaContable:
        "proveedores/getImputContablesByEmpresaxFechaContable",
      getTipoComprobanteProveedorById:
        "proveedores/getTipoComprobanteProveedorById",
      getTipoCompTieneNumAutomatico:
        "proveedores/getTipoCompTieneNumAutomatico",
      grabarPagosVarios: "fondos/grabarPagosVarios",
      getDatosEditPVIV: "fondos/getDatosEditPVIV",
      validateEditPagosVarios: "fondos/validateEditPagosVarios",
      getAllTipoComprobantes: "proveedores/getAllTipoComprobantes",
      setAlert: "user/setAlert",
    }),
    async setCombos() {
      const parametrosGen = await this.getParametrosGeneralesProveedor();
      this.parametroGeneralMultimoneda = parametrosGen.multimoneda;
      const tiposValores = await this.GetTiposValoresRetenciones();
      this.tipoRetencionesItems = tiposValores;
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresas = entidadesFacturantes;
      this.monedas = await this.getMonedasTaxonomy("habCarga");
      this.monedaPorDefecto = this.monedas.find((x) => x.bool == true);
      this.monedaPorDefectoNom = this.monedaPorDefecto.value;
      this.monedaSelected = !this.$route.params.mProvId
        ? this.monedaPorDefecto
        : null;
      if (!this.$route.params.mProvId) this.loadView = false;
    },
    async validateEditPVIV() {
      const response = await this.validateEditPagosVarios(
        this.$route.params.mprovId
      );
      if (
        response &&
        response.toString().trim() !== "" &&
        response.toString().trim() == "El comprobante tiene valores revertidos."
      ) {
        //la validacion solo dice que hay valores revertidos entonces se puede editar fecha, fechaContable, concepto y boton guardar. Sino deshabilita todo
        this.enableSaveBtn = true;
        this.canEdit = false;
        this.setAlert({
          type: "warning",
          message: response.toString().trim(),
        });
      } else if (
        response &&
        response.toString().trim() !== "" &&
        response.toString().trim() !==
          "El comprobante tiene valores revertidos."
      ) {
        this.canEdit = false;
        this.enableSaveBtn = false;
        this.setAlert({
          type: "warning",
          message: response.toString().trim() + " No se puede editar",
        });
      } else this.canEdit = true;
    },
    async setDatosEditPVIV() {
      this.title = "Editar ingresos / egresos varios";
      const response = await this.getDatosEditPVIV(this.$route.params.mprovId);
      if (response) {
        this.empresaSelected = this.empresas.find(
          (x) => x.id == response.entFacId
        );
        this.concepto = response.obs.toString().trim();
        // fecha
        this.fechaSelected = response.fechaString;
        let partesFechaToFomat = this.fechaSelected.split("/");
        this.fecha = `${partesFechaToFomat[2]}-${partesFechaToFomat[1]}-${partesFechaToFomat[0]}`;
        // fecha contable
        this.fechaContableSelected = response.fechaContString;
        let partesFechaContableToFomat = this.fechaContableSelected.split("/");
        this.fechaContable = `${partesFechaContableToFomat[2]}-${partesFechaContableToFomat[1]}-${partesFechaContableToFomat[0]}`;
        if (this.esAnulacion) {
          let tipoComps = await this.getAllTipoComprobantes(-1);
          let tipoCompFound = tipoComps.find((x) => x.id == response.tCompId);
          this.tipoComprobanteSelected = {
            tCompId: tipoCompFound.id,
            tCompNombre: tipoCompFound.value,
          };
          this.tipoComprobantesItems.unshift(this.tipoComprobanteSelected);
          this.letraComprobante = response.letra.toString();
          this.nroComprobante = response.numero;
          this.sucursalComprobante = response.suc;
        } else {
          await this.setImputacionContableEmpresa();
          this.tipoComprobanteSelected = this.tipoComprobantesItems.find(
            (x) => x.tCompId == response.tCompId
          );

          await this.setDatosTipoComprobante();
          this.letraComprobante = response.letra;
        }
        this.sucursalComprobante = response.suc;
        this.nroComprobante = response.numero;
        this.monedaSelected = response.monedaIdComp
          ? this.monedas.find((x) => x.id == response.monedaIdComp)
          : this.monedaPorDefecto;
        this.importe = response.total;
        this.cotizacion = !response.monedaIdComp ? null : response.cotizacion;
        this.importeMonedaExtranjera =
          response.monedaIdComp != null ? response.totalComp : null;
        if (response.listaValores?.length > 0) {
          this.valoresItems = response.listaValores;
          this.valoresItems = this.valoresItems.map((item) => {
            if (item.fecha !== null) {
              let date = new Date(item.fecha);
              item.fecha = date.toLocaleDateString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
            }
            return item;
          });
          this.recalcularValores();
        }
        if (response.listaImputContable?.length > 0) {
          this.totalImputacionContable = response.listaImputContable.reduce(
            (sum, impCont) => {
              return sum + impCont.importe;
            },
            0
          );
          this.imputacionContableItems = response.listaImputContable.map(
            (imputCble, index) => {
              return {
                ...imputCble,
                internId: index + 1,
              };
            }
          );
        }
        if (response.listaCentrosCostos?.length > 0) {
          this.totalCentrosDeCostos = response.listaCentrosCostos.reduce(
            (sum, cc) => {
              return sum + cc.importe;
            },
            0
          );
          this.centroCostosItems = response.listaCentrosCostos.map(
            (centro, index) => {
              return {
                ...centro,
                internId: index + 1,
              };
            }
          );
        }
        if (response.listaPagosRetToEdit) {
          this.selectRetenciones = response.listaPagosRetToEdit;
          this.recalcularImportes();
        }
      }
      setTimeout(() => {
        this.loadView = false;
      }, 300);
    },
    async getTipoComprobanteAbmByEmpresa() {
      this.tipoComprobantesItems = await this.getTipoCompPvIvAbm(
        this.empresaSelected.id
      );
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    toggleModalReteneciones() {
      this.openModalSelectRetenciones = !this.openModalSelectRetenciones;
      this.recalcularImportes();
    },
    recalcularImportes() {
      this.importe = this.selectRetenciones.reduce(
        (acc, item) => parseFloat((acc + item.imp).toFixed(2)),
        0
      );
      if (this.monedaDistintaDefecto) {
        this.importeMonedaExtranjera = parseFloat(
          (this.importe / this.cotizacion).toFixed(2)
        );
        this.selectRetenciones.map((x) => {
          parseFloat((x.importeComp = x.imp / this.cotizacion).toFixed(2));
        });
      }
    },
    recalcularValores() {
      this.totalValores = this.valoresItems.reduce(
        (acc, item) => parseFloat((acc + item.imp).toFixed(2)),
        0
      );
      if (this.monedaDistintaDefecto) {
        this.totalValoresMonedaExtranjera = parseFloat(
          (this.totalValores / this.cotizacion).toFixed(2)
        );
        this.valoresItems.map((x) => {
          if (!x.importeComp)
            parseFloat((x.importeComp = x.imp / this.cotizacion).toFixed(2));
        });
      }

      if (
        this.monedaDistintaDefecto &&
        this.importeMonedaExtranjera == this.totalValoresMonedaExtranjera
      )
        this.totalValores = this.importe; //le asigno directamente el valor para que no de distinto
    },
    async getRetencionesByFilters() {
      this.customizeFiltersApplied();
      this.isLoadingTable = true;
      const params = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        entFacId: this.empresaSelected?.id,
        tvId: this.tipoRetencionSelected?.id,
        mProvId: 0,
        tipoFecha: this.tipoFechaSelected.id,
      };
      try {
        this.itemsRetenciones = await this.getRetencionesAPagar(params);
      } catch {}
      this.isLoadingTable = false;
    },
    async getCotizacion(monedaId) {
      if (monedaId != null && !this.$route.params.mprovId) {
        this.importeMonedaExtranjera = 0.0;
        this.importe = 0.0;
        this.cotizacionLoading = true;
        const payload = {
          monedaId: monedaId,
          fechaComprobante: this.fecha,
        };
        try {
          const response = await this.getCotizacionMoneda(payload);
          if (response) this.cotizacion = response;
          else this.cotizacion = null;
        } catch (error) {
        } finally {
          this.cotizacionLoading = false;
        }
      }
    },
    calcularTotalEnPeso() {
      this.importe = parseFloat(
        (this.importeMonedaExtranjera * this.cotizacion).toFixed(2)
      );
    },
    calcularTotalEnDolar() {
      this.importeMonedaExtranjera = parseFloat(
        (this.importe / this.cotizacion).toFixed(2)
      );
    },
    selectWhenClickRow(item) {
      const isSelected = this.selectRetenciones.some(
        (selectedItem) => selectedItem.mpValId === item.mpValId
      );
      if (!isSelected) this.selectRetenciones.push(item);
      else
        this.selectRetenciones = this.selectRetenciones.filter(
          (x) => x !== item
        );
    },
    selectAllToggle() {
      if (this.selectRetenciones?.length != this.itemsRetenciones?.length) {
        this.selectRetenciones = this.itemsRetenciones;
        this.setAlert({
          type: "info",
          message: "Todas las retenciones han sido seleccionados.",
        });
      } else {
        this.selectRetenciones = [];
        this.setAlert({
          type: "info",
          message: "Todas las retenciones han sido deseleccionados.",
        });
      }
    },
    async setImputacionContableEmpresa() {
      await this.getTipoComprobanteAbmByEmpresa();
      if (this.empresaSelected.id != null && this.fechaContable != null) {
        try {
          const data = {
            entFacId: this.empresaSelected.id,
            fechaContable: this.fechaContable,
          };
          const response = await this.getImputContablesByEmpresaxFechaContable(
            data
          );
          this.datosImputacionContable = response;
          this.empresaIdConta = response?.empresaIdConta;
        } catch (error) {
          this.datosImputacionContable = null;
          this.empresaIdConta = null;
        }
        return true;
      } else return false;
    },
    async setDatosTipoComprobante() {
      if (
        this.empresaSelected &&
        !this.showValores &&
        this.tipoComprobanteSelected != null &&
        !this.tipoComprobanteSelected.llevaImputCble
      ) {
        this.text = "VALORES";
        this.showValores = true;
        this.centroCostosItems = [];
        this.imputacionContableItems = [];
        this.totalImputacionContable = 0;
        this.totalCentrosDeCostos = 0;
      }
      this.importe = 0.0;
      this.importeMonedaExtranjera = 0.0;
      this.tCompEsPagoRetencion =
        this.tipoComprobanteSelected.tCompEsPagoRetencion;
      if (this.tipoComprobanteSelected != null) {
        const response = await this.getTipoComprobanteProveedorById(
          this.tipoComprobanteSelected.tCompId
        );
        let datosTipoComprobante = response;
        // this.tCompEditaFecha = datosTipoComprobante.tCompEditaFecha;
        this.tCompSigno = datosTipoComprobante.tCompSigno;
        this.tieneNumeradorAutomatico = datosTipoComprobante.numAuto;
        if (datosTipoComprobante?.numAutoLetra?.trim()?.length > 0)
          this.letraComprobante = datosTipoComprobante.numAutoLetra.toString();

        if (datosTipoComprobante.numAutoSuc)
          this.sucursalComprobante = datosTipoComprobante.numAutoSuc.toString();

        await this.getTipoCompNumAutomatico(
          this.tipoComprobanteSelected.tCompId,
          datosTipoComprobante.numAutoLetra,
          datosTipoComprobante.numAutoSuc
        );
        if (!this.tieneNumeradorAutomatico) {
          this.setAlert({
            type: "warning",
            message: `No está configurado el numerador autómatico. \n Tipo de comprobante: '${datosTipoComprobante.tCompNombre}' \nLetra: '${datosTipoComprobante.numAutoLetra}' \nSucursal: '${datosTipoComprobante.numAutoSuc}'`,
          });
        }
      } else {
        this.letraComprobante = "";
        this.sucursalComprobante = "";
        this.nroComprobante = "";
        this.tieneNumeradorAutomatico = false;
      }
    },
    async getTipoCompNumAutomatico(tCompId, letra, sucursal) {
      if (letra && tCompId && sucursal) {
        this.nroComprobante = "0";

        if (tCompId != null && letra != null && sucursal != null) {
          this.tieneNumeradorAutomatico =
            await this.getTipoCompTieneNumAutomatico({
              tCompId: tCompId,
              numAutoSuc: sucursal,
              numAutoLetra: letra,
            });
        } else this.tieneNumeradorAutomatico = false;
      }
    },
    reloadTablesImputacionContable(
      centrosCostos,
      imputacionContable,
      totalCentrosDeCostos,
      totalImputacionContable
    ) {
      this.centroCostosItems = centrosCostos;
      this.imputacionContableItems = imputacionContable;
      this.totalCentrosDeCostos = totalCentrosDeCostos;
      this.totalImputacionContable = totalImputacionContable;
    },
    setValoresCartera(itemsSelected) {
      if (itemsSelected) {
        this.valoresItems = this.valoresItems.concat(itemsSelected);
      }
    },
    toggleNuevoTipoValor() {
      this.importeSugerido = this.monedaDistintaDefecto
        ? parseFloat(
            (
              this.importeMonedaExtranjera - this.totalValoresMonedaExtranjera
            ).toFixed(2)
          )
        : parseFloat((this.importe - this.totalValores).toFixed(2));
      this.openModalNuevoValor = !this.openModalNuevoValor;
      this.recalcularValores();
    },
    toggleNuevoImputacionContable() {
      this.imputacionContableModal = !this.imputacionContableModal;

      if (!this.imputacionContableModal) {
        this.centroCostosItemsProp = [];
        this.imputacionContableItemsProp = [];
      } else {
        this.centroCostosItemsProp = this.centroCostosItems;
        this.imputacionContableItemsProp = this.imputacionContableItems;
      }
    },
    toggleModalValoresCartera() {
      this.buscarValoresCarteraModal = !this.buscarValoresCarteraModal;
      this.recalcularValores();
    },
    addTipoDeValor(detalle) {
      if (detalle) this.valoresItems.push(detalle);
    },
    async saveEditPVIV() {
      if (this.importe !== this.totalValores) {
        this.setAlert({
          type: "error",
          message: `El total del importe debe ser igual al total de los valores.`,
        });
        this.loadingBtnSave = false;
        return;
      }
      if (
        this.imputacionContableItems.length > 0 &&
        this.totalImputacionContable != this.importe
      ) {
        this.setAlert({
          type: "error",
          message: `El total de la imputación contable debe ser igual al total del importe.`,
        });
        this.loadingBtnSave = false;
        return;
      }
      this.loadingBtnSave = true;
      const data = {
        sesion: 0,
        mProv_id: this.$route.params.mprovId ? this.$route.params.mprovId : 0,
        fecha: this.fecha,
        fecContable: this.fechaContable,
        tCompId: this.tipoComprobanteSelected.tCompId,
        letra: this.letraComprobante,
        suc: this.sucursalComprobante.toString(),
        numero: parseInt(this.nroComprobante),
        tIvaId: 0,
        neto: 0,
        iva: 0,
        percepcionIva: 0,
        percepcionIb: 0,
        impuestosInternos: 0,
        otrosConceptos: 0,
        total: this.importe,
        monedaId: this.monedaPorDefecto.id,
        entFacId: this.empresaSelected.id,
        totalComp: this.monedaDistintaDefecto
          ? this.importeMonedaExtranjera
          : 0,
        obs: this.concepto,
        signo: this.tCompSigno,
        toId: null,
        periodo: null,
        provIdCdo: null,
        caeCaiProv: null,
        caeCaiProvFecVen: null,
        tEmision: null,
        ivaComp: null,
        percepcionIvaComp: null,
        percepcionIbComp: null,
        impuestosInternosComp: null,
        cotizacion: this.monedaDistintaDefecto ? this.cotizacion : null,
        otrosConceptosComp: null,
        tipoComprobanteDif: "",
        tcompTieneNumeradorAutomatico: this.tieneNumeradorAutomatico,
        monedaIdComp: this.monedaDistintaDefecto
          ? this.monedaSelected.id
          : null,
        listaValores:
          this.valoresItems?.length == 0
            ? []
            : this.valoresItems.map((item) => {
                if (item.fecha !== null && item.fecha !== undefined)
                  item.fecha = helpers.parseDateToIso(item.fecha);
                return item;
              }),
        listaComprobante: [],
        listaImputContable: this.imputacionContableItems,
        listaCentrosCostos: this.centroCostosItems,
        listaPercepcionIB: [],
        listaAlicuota: [],
        listaPagosRet: this.selectRetenciones.map((x) => x.mpValId),
      };
      try {
        const response = await this.grabarPagosVarios(data);

        if (response && response.idError == 0) {
          this.setAlert({
            type: "success",
            message: `Pagos / ingresos grabados con éxito. ${response.mensaje}`,
          });
          this.volver();
        } else {
          this.setAlert({
            type: "error",
            message: `Error al grabar: ${response.mensaje}`,
          });
        }
      } catch {}
      this.loadingBtnSave = false;
    },
    switchTab(val) {
      if (val) this.showValores = true;
      else this.showValores = false;
    },
    customizeFiltersApplied() {
      this.showFilters = false;
      this.filtersApplied = [];
      if (this.tipoFechaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "tipoFechaSelected",
          label: "Tipo fecha",
          model: this.tipoFechaSelected,
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaDesdeSelected",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "fechaHastaSelected",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.empresaSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "empresaSelected",
          label: "Tipo",
          model: this.empresaSelected.value,
        });
      }
      if (this.tipoRetencionSelected) {
        this.filtersApplied.splice(6, 1, {
          key: "tipoRetencionSelected",
          label: "Tipo retención",
          model: this.tipoRetencionSelected.value,
        });
      }
    },
    volver() {
      this.$router.push({
        name: "ConsultaPVIV",
      });
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

<style scoped>
</style>